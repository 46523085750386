<div class="flex flex-col items-start gap-2">
  <app-text-input
    [text]="corporateDesign().primaryColor"
    (textChange)="onPrimaryColorChange($event)"
    label="primaryColor" />
  <app-text-input
    [text]="corporateDesign().secondaryColor"
    (textChange)="onSecondaryColorChange($event)"
    label="Sekundärfarbe" />
  <div class="w-full border-b-2"></div>
  <app-number-input
    [number]="corporateDesign().fontSize"
    (numberChange)="onFontSizeChange($event)"
    label="Schriftgröße" />
  <app-font-select
    [font]="corporateDesign().font"
    (fontChange)="onFontChange($event)"
    label="Schriftgröße" />

  <button
    (click)="save()"
    type="submit"
    class="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
    Save
  </button>
</div>
