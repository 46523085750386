<div class="flex h-screen bg-gray-100">
  <!-- sidebar -->
  <div class="hidden w-64 flex-col bg-gray-800 md:flex">
    <div class="flex h-16 items-center justify-center bg-gray-900">
      <span class="font-bold uppercase text-white">Sidebar</span>
    </div>
    <div class="flex flex-1 flex-col overflow-y-auto">
      <nav class="flex-1 bg-gray-800 px-2 py-4">
        <a
          class="mt-2 flex items-center px-4 py-2 text-gray-100 hover:bg-gray-700"
          href="#"
          routerLink="/cms/corporate">
          <svg
            class="mr-2 h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6 18L18 6M6 6l12 12"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2" />
          </svg>
          Corporate Design
        </a>
        <a
          class="mt-2 flex items-center px-4 py-2 text-gray-100 hover:bg-gray-700"
          href="#"
          routerLink="/cms/header">
          <svg
            class="mr-2 h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 10V3L4 14h7v7l9-11h-7z"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2" />
          </svg>
          Header
        </a>
        <a
          class="mt-2 flex items-center px-4 py-2 text-gray-100 hover:bg-gray-700"
          href="#"
          routerLink="/cms/content-header">
          <svg
            class="mr-2 h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 10V3L4 14h7v7l9-11h-7z"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2" />
          </svg>
          ContentHeader
        </a>
        <a
          class="mt-2 flex items-center px-4 py-2 text-gray-100 hover:bg-gray-700"
          href="#"
          routerLink="/cms/footer">
          <svg
            class="mr-2 h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 10V3L4 14h7v7l9-11h-7z"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2" />
          </svg>
          Footer
        </a>
        <a
          class="mt-2 flex items-center px-4 py-2 text-gray-100 hover:bg-gray-700"
          href="#"
          routerLink="/cms/fonts">
          <ng-icon name="matFontDownload" />
          Fonts
        </a>
      </nav>
    </div>
  </div>

  <!-- Main content -->
  <div class="flex flex-1 flex-col overflow-y-auto">
    <div
      class="flex h-16 items-center justify-between border-b border-gray-200 bg-white">
      <div class="flex items-center px-4">
        <button class="text-gray-500 focus:text-gray-700 focus:outline-none">
          <svg
            class="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 6h16M4 12h16M4 18h16"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2" />
          </svg>
        </button>
        <input
          class="mx-4 w-full rounded-md border px-4 py-2"
          placeholder="Search"
          type="text" />
      </div>
      <div class="flex items-center pr-4">
        <button
          class="flex items-center text-gray-500 hover:text-gray-700 focus:text-gray-700 focus:outline-none">
          <svg
            class="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 19l-7-7 7-7m5 14l7-7-7-7"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2" />
          </svg>
        </button>
      </div>
    </div>
    <div class="p-4">
      <h1 class="text-2xl font-bold">Welcome to my dashboard!</h1>
      <p class="mt-2 text-gray-600">
        This is an example dashboard using Tailwind CSS.
      </p>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
