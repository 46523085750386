import { inject, Pipe, PipeTransform } from '@angular/core';
import { IText } from '../../entities/text-entity';
import { LanguageService } from '../../services/language/language.service';

@Pipe({
  name: 'language',
  standalone: true,
})
export class LanguagePipe implements PipeTransform {
  languageService = inject(LanguageService);

  transform(texts: IText[]): string {
    return texts.filter(l => l.isoCode === this.languageService.iso)[0].value;
  }
}
