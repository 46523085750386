<div class="text-gray-600 outline-none focus:outline-none dark:text-gray-300">
  <div class="flex rounded-lg bg-white p-4">
    <select
      class="p- peer h-10 rounded-l-lg bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:border-rose-600 focus:outline-none focus:ring-sky-600"
      [formControl]="selectedValueControl">
      <option
        class="cursor-pointer font-medium"
        value="filter"
        *ngFor="let c of countries"
        [ngValue]="c.iso">
        {{ c.name }}
      </option>
    </select>
    <div class="relative bg-inherit">
      <input
        [value]="text"
        (change)="updateText($event)"
        class="peer h-10 w-72 rounded-r-lg bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:border-rose-600 focus:outline-none focus:ring-sky-600"
        data-testid="text"
        id="text"
        placeholder="."
        type="text" />
      <label
        class="absolute -top-3 left-0 mx-1 cursor-text bg-inherit px-1 text-sm text-gray-500 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:text-sm peer-focus:text-sky-600"
        for="text">
        {{ label() }}
      </label>
    </div>
  </div>
</div>
