import { Injectable, signal, WritableSignal } from '@angular/core';
import { IFont } from '../../entities/font-entity';
import { Observable, switchMap } from 'rxjs';
import { FontGateway } from './fontsGateway';

@Injectable({
  providedIn: 'root',
})
export class FontsService {
  private fontGateway = new FontGateway();

  private fonts: WritableSignal<IFont[]> = signal([{ name: '', filename: '' }]);

  constructor() {
    this.fontGateway.get().subscribe(fonts => {
      this.fonts.set(fonts);
    });
  }

  public get(): WritableSignal<IFont[]> {
    return this.fonts;
  }

  public updateFont(formData: FormData): Observable<IFont[]> {
    return this.fontGateway
      .upload(formData)
      .pipe(switchMap(() => this.fontGateway.get()));
  }

  public deleteFont(font: IFont) {
    return this.fontGateway
      .delete(font)
      .pipe(switchMap(() => this.fontGateway.get()));
  }
}
