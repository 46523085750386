<div class="rounded-lg bg-white p-4">
  <div class="relative bg-inherit">
    <input
      [value]="number()"
      (input)="updateNumber($event)"
      class="peer h-10 w-36 rounded-lg bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:border-rose-600 focus:outline-none focus:ring-sky-600"
      data-testid="secondaryColor"
      id="secondaryColor"
      placeholder="."
      type="number" />
    <label
      class="absolute -top-3 left-0 mx-1 cursor-text bg-inherit px-1 text-sm text-gray-500 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:text-sm peer-focus:text-sky-600"
      for="secondaryColor">
      {{ label() }}
    </label>
  </div>
</div>
