import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private readonly _client: string;

  constructor() {
    this._client = 'bsvg';
  }

  get getClient(): string {
    return this._client;
  }
}
