import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-text-input',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './text-input.component.html',
  styles: ``,
})
export class TextInputComponent {
  text = input<string>('');
  label = input.required<string>();
  textChange = output<string>();

  updateText($event: Event) {
    const target = $event.target as HTMLInputElement;
    this.textChange.emit(target.value);
  }
}
