import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { ClientService } from '../client/client.service';
import { Observable, switchMap } from 'rxjs';
import { HeaderGateway } from './headerGateway';
import { IImage } from '../../entities/image-entity';
import { IText } from '../../entities/text-entity';

export interface IHeader {
  id?: number;
  client: string;
  logo: IImage;
  headlines: IText[];
  leftImage: IImage;
  rightImage: IImage;
}

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private clientService = inject(ClientService);

  private headerGateway = new HeaderGateway();

  private header: WritableSignal<IHeader> = signal({
    client: this.clientService.getClient,
    logo: { filename: '', alt: '' },
    headlines: [{ value: '', isoCode: 'deu' }],
    leftImage: { filename: '', alt: '' },
    rightImage: { filename: '', alt: '' },
  });

  constructor() {
    this.headerGateway.get().subscribe(header => {
      this.header.set(header);
    });
  }

  public get(): WritableSignal<IHeader> {
    return this.header;
  }

  public save(header: IHeader): Observable<IHeader> {
    return this.headerGateway.save(header);
  }

  public upload(formData: FormData): Observable<IHeader> {
    return this.headerGateway
      .upload(formData)
      .pipe(switchMap(() => this.headerGateway.get()));
  }
}
