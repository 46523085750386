import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'template',
  standalone: true,
})
export class TemplatePipe implements PipeTransform {
  transform(template: string, values: Record<string, string>): string {
    return template.replace(/{{(.*?)}}/g, (_, key) => values[key.trim()] || '');
  }
}
