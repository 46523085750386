import { IHeader } from './header.service';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { ClientService } from '../client/client.service';
import { IGetGateway, ISaveGateway } from '../gateways';

export class HeaderGateway
  implements IGetGateway<null, IHeader>, ISaveGateway<IHeader, IHeader>
{
  private http = inject(HttpClient);
  private configService = inject(ConfigService);
  private clientService = inject(ClientService);

  private apiUrl = this.configService.getApiUrl;
  private client = this.clientService.getClient;

  get(): Observable<IHeader> {
    return this.http.get<IHeader>(this.apiUrl + '/header/' + this.client);
  }

  save(query: IHeader): Observable<IHeader> {
    return this.http.post<IHeader>(this.apiUrl + '/header/', query);
  }

  upload(query: FormData): Observable<IHeader> {
    return this.http.post<IHeader>(this.apiUrl + '/header/upload', query);
  }
}
