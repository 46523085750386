<div
  class="flex min-h-10 items-center gap-2 p-2"
  [style.background-color]="corporateDesign().secondaryColor">
  <div class="hidden flex-grow-0 md:flex">
    <img [src]="leftPath()" [alt]="header().leftImage.alt" class="max-h-10" />
  </div>
  <div class="flex flex-grow justify-between">
    <div class="flex items-center gap-2">
      <img [src]="logoPath()" [alt]="header().logo.alt" class="max-h-10" />
      <span class="hidden md:block">{{
        header().headlines | language | template: { name: 'Amon' }
      }}</span>
    </div>
    <div class="flex items-center">Buttons</div>
  </div>
  <div class="hidden flex-grow-0 md:flex">
    <img [src]="rightPath()" [alt]="header().rightImage.alt" class="max-h-10" />
  </div>
</div>
