import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { ClientService } from '../client/client.service';
import { Observable } from 'rxjs';
import { CorporateDesignGateway } from './corporateDesignGateway';
import { IFont } from '../../entities/font-entity';

export interface ICorporateDesign {
  id?: number;
  client: string;
  primaryColor: string;
  secondaryColor: string;
  fontSize: number;
  font: IFont;
}

@Injectable({
  providedIn: 'root',
})
export class CorporateDesignService {
  private clientService = inject(ClientService);

  private corporateDesignGateway = new CorporateDesignGateway();

  private corporateDesign: WritableSignal<ICorporateDesign> = signal({
    client: this.clientService.getClient,
    primaryColor: '',
    secondaryColor: '',
    fontSize: 0,
    font: { name: '', filename: '' },
  });

  constructor() {
    this.corporateDesignGateway.get().subscribe(corporateDesign => {
      this.corporateDesign.set(corporateDesign);
    });
  }

  public get(): WritableSignal<ICorporateDesign> {
    return this.corporateDesign;
  }

  public save(corporateDesign: ICorporateDesign): Observable<ICorporateDesign> {
    return this.corporateDesignGateway.save(corporateDesign);
  }
}
