import { Component, inject, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CorporateDesignService,
  ICorporateDesign,
} from '../../../services/corporateDesign/corporate-design.service';
import { ContentHeaderComponent } from '../../../components/contentHeader/contentHeader.component';

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [CommonModule, ContentHeaderComponent],
  templateUrl: './landing-page.component.html',
  styles: ``,
})
export class LandingPageComponent {
  corporateDesign: Signal<ICorporateDesign>;
  private corporateDesignService: CorporateDesignService = inject(
    CorporateDesignService
  );

  constructor() {
    this.corporateDesign = this.corporateDesignService.get();
  }
}
