import { Component, effect, inject, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageService } from '../../../services/language/language.service';
import { IText } from '../../../entities/text-entity';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-text-language-input',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './text-language-input.component.html',
  styles: ``,
})
export class TextLanguageInputComponent {
  texts = input<IText[]>([]);
  langIso = input<string>('');
  label = input.required<string>();
  textChange = output<string>();
  langChange = output<string>();

  languageService = inject(LanguageService);
  countries = this.languageService.languages;

  text = this.texts().filter(t => t.isoCode === this.langIso())[0]?.value;

  selectedValueControl = new FormControl(this.langIso());

  constructor() {
    this.selectedValueControl.valueChanges.subscribe(newValue => {
      if (newValue) this.langChange.emit(newValue);
    });
    effect(() => {
      this.selectedValueControl.setValue(this.langIso());
      this.text = this.texts().filter(
        t => t.isoCode === this.langIso()
      )[0]?.value;
    });
  }

  updateText($event: Event) {
    const target = $event.target as HTMLInputElement;
    this.text = target.value;
    this.textChange.emit(target.value);
  }
}
