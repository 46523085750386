import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { ClientService } from '../client/client.service';
import { Observable, switchMap } from 'rxjs';
import { ContentHeaderGateway } from './contentHeaderGateway';
import { IImage } from '../../entities/image-entity';
import { IText } from '../../entities/text-entity';
import { IFont } from '../../entities/font-entity';

export interface IContentHeader {
  id?: number;
  client: string;
  banner: IImage;
  headlines: IText[];
  headlineFontSize: number;
  headlineFont: IFont;
  subheadlines: IText[];
  subheadlineFontSize: number;
  subheadlineFont: IFont;
}

@Injectable({
  providedIn: 'root',
})
export class ContentHeaderService {
  private clientService = inject(ClientService);

  private contentHeaderGateway = new ContentHeaderGateway();

  private contentHeader: WritableSignal<IContentHeader> = signal({
    client: this.clientService.getClient,
    banner: { filename: '', alt: '' },
    headlines: [{ value: '', isoCode: 'deu' }],
    headlineFontSize: 0,
    headlineFont: { name: '', filename: '' },
    subheadlines: [{ value: '', isoCode: 'deu' }],
    subheadlineFontSize: 0,
    subheadlineFont: { name: '', filename: '' },
  });

  constructor() {
    this.contentHeaderGateway.get().subscribe(contentHeader => {
      this.contentHeader.set(contentHeader);
    });
  }

  public get(): WritableSignal<IContentHeader> {
    return this.contentHeader;
  }

  public save(contentHeader: IContentHeader): Observable<IContentHeader> {
    return this.contentHeaderGateway.save(contentHeader);
  }

  public updateBanner(formData: FormData): Observable<IContentHeader> {
    return this.contentHeaderGateway
      .upload(formData)
      .pipe(switchMap(() => this.contentHeaderGateway.get()));
  }
}
