import { ICorporateDesign } from './corporate-design.service';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { ClientService } from '../client/client.service';
import { IGetGateway, ISaveGateway, IUploadGateway } from '../gateways';

export class CorporateDesignGateway
  implements
    IGetGateway<null, ICorporateDesign>,
    ISaveGateway<ICorporateDesign, ICorporateDesign>,
    IUploadGateway<FormData, ICorporateDesign>
{
  private http = inject(HttpClient);
  private configService = inject(ConfigService);
  private clientService = inject(ClientService);

  private apiUrl = this.configService.getApiUrl;
  private client = this.clientService.getClient;

  get(): Observable<ICorporateDesign> {
    return this.http.get<ICorporateDesign>(
      this.apiUrl + '/corporate-design/' + this.client
    );
  }

  save(query: ICorporateDesign): Observable<ICorporateDesign> {
    return this.http.post<ICorporateDesign>(
      this.apiUrl + '/corporate-design/',
      query
    );
  }

  upload(query: FormData): Observable<ICorporateDesign> {
    return this.http.post<ICorporateDesign>(
      this.apiUrl + '/corporate-design/font',
      query
    );
  }
}
