<div class="text-gray-600 outline-none focus:outline-none dark:text-gray-300">
  <div class="flex rounded bg-white p-4">
    <select
      class="p- peer h-10 rounded bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:border-rose-600 focus:outline-none focus:ring-sky-600"
      [formControl]="selectedValueControl">
      <option
        class="cursor-pointer font-medium"
        value="filter"
        *ngFor="let font of fonts()"
        [ngValue]="font.id">
        {{ font.name }}
      </option>
    </select>
  </div>
</div>
