import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-footer-page',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './footer-page.component.html',
  styles: ``,
})
export class FooterPageComponent {}
