<div class="relative">
  <div class="absolute flex h-full w-full flex-col items-center justify-center">
    <span
      [style.font-size]="contentHeader().headlineFontSize + 'px'"
      style="font-family: '{{ contentHeader().headlineFont.name }}'">
      {{ contentHeader().headlines | language }}
    </span>
    <span
      [style.font-size]="contentHeader().subheadlineFontSize + 'px'"
      style="font-family: '{{ contentHeader().subheadlineFont.name }}'">
      {{ contentHeader().subheadlines | language }}
    </span>
  </div>
  <img
    class="mx-auto max-h-[400px]"
    [src]="bannerPath()"
    [alt]="contentHeader().banner.alt" />
</div>
