import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private _iso = 'deu';

  private _languages = [
    { id: 1, name: 'Deutsch', iso: 'deu' },
    { id: 2, name: 'English', iso: 'gbr' },
    { id: 3, name: 'Einfach', iso: 'ein' },
  ];

  get iso(): string {
    return this._iso;
  }

  get languages(): { iso: string; name: string; id: number }[] {
    return this._languages;
  }
}
