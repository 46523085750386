<div class="flex flex-col items-start gap-2">
  <app-text-language-input
    [texts]="contentHeader().headlines"
    [langIso]="langIso"
    label="Alt Headline"
    (textChange)="onHeadlineChange($event)"
    (langChange)="onLangChange($event)" />
  <app-number-input
    [number]="contentHeader().headlineFontSize"
    (numberChange)="onHeadlineFontSizeChange($event)"
    label="Schriftgröße" />
  <app-font-select
    [font]="contentHeader().headlineFont"
    (fontChange)="onHeadlineFontChange($event)"
    label="Schriftgröße" />
  <div class="w-full border-b-2"></div>
  <app-text-language-input
    [texts]="contentHeader().subheadlines"
    [langIso]="langIso"
    label="Alt Subheadline"
    (textChange)="onSubheadlineChange($event)"
    (langChange)="onLangChange($event)" />
  <app-number-input
    [number]="contentHeader().subheadlineFontSize"
    (numberChange)="onSubheadlineFontSizeChange($event)"
    label="Schriftgröße" />
  <app-font-select
    [font]="contentHeader().subheadlineFont"
    (fontChange)="onSubheadlineFontChange($event)"
    label="Schriftgröße" />
  <div class="w-full border-b-2"></div>
  <app-image-input
    [alt]="contentHeader().banner.alt"
    label="Alt Banner"
    (textChange)="onBannerAltChange($event)"
    (fileChange)="selectBanner($event)" />

  <button
    (click)="save()"
    type="submit"
    class="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
    Save
  </button>
</div>
