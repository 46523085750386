import { Component, inject, OnDestroy, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CorporateDesignService,
  ICorporateDesign,
} from '../../../services/corporateDesign/corporate-design.service';
import { Subscription } from 'rxjs';
import { TextInputComponent } from '../../../components/inputs/textInput/text-input.component';
import { NumberInputComponent } from '../../../components/inputs/numbertInput/number-input.component';
import { FontSelectComponent } from '../../../components/inputs/FontSelect/font-select.component';
import { IFont } from '../../../entities/font-entity';

@Component({
  selector: 'app-corporate-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextInputComponent,
    NumberInputComponent,
    FontSelectComponent,
  ],
  templateUrl: './corporate-page.component.html',
  styles: ``,
})
export class CorporatePageComponent implements OnDestroy {
  corporateDesignService = inject(CorporateDesignService);

  corporateDesign: WritableSignal<ICorporateDesign> =
    this.corporateDesignService.get();

  private subscription: Subscription = new Subscription();

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  save(): void {
    this.subscription.add(
      this.corporateDesignService
        .save(this.corporateDesign())
        .subscribe(cd => this.corporateDesign.set(cd))
    );
  }

  onPrimaryColorChange(value: string) {
    const cd = this.corporateDesign();
    cd.primaryColor = value;
    this.corporateDesign.set(cd);
  }

  onSecondaryColorChange(value: string) {
    const cd = this.corporateDesign();
    cd.secondaryColor = value;
    this.corporateDesign.set(cd);
  }

  onFontSizeChange(value: number) {
    const cd = this.corporateDesign();
    cd.fontSize = value;
    this.corporateDesign.set(cd);
  }

  onFontChange(value: IFont) {
    const cd = this.corporateDesign();
    cd.font = value;
    this.corporateDesign.set(cd);
  }
}
