<div class="flex flex-col items-start gap-2">
  <app-text-language-input
    [texts]="header().headlines"
    [langIso]="langIso"
    label="Alt Text"
    (textChange)="onTextChange($event)"
    (langChange)="onLangChange($event)" />

  <app-image-input
    [alt]="header().logo.alt"
    label="Alt Logo"
    (textChange)="onLogoAltChange($event)"
    (fileChange)="selectLogo($event)" />
  <app-image-input
    [alt]="header().leftImage.alt"
    label="Alt Left Logo"
    (textChange)="onLeftAltChange($event)"
    (fileChange)="selectLeft($event)" />
  <app-image-input
    [alt]="header().rightImage.alt"
    label="Alt Right Logo"
    (textChange)="onRightAltChange($event)"
    (fileChange)="selectRight($event)" />

  <button
    (click)="save()"
    type="submit"
    class="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
    Save
  </button>
</div>
