import { Route } from '@angular/router';
import { LandingPageComponent } from './pages/shop/landing/landing-page.component';
import { HeaderPageComponent } from './pages/cms/header/header-page.component';
import { FooterPageComponent } from './pages/cms/footer/footer-page.component';
import { CorporatePageComponent } from './pages/cms/corporate/corporate-page.component';
import { CmsPageComponent } from './pages/cms/cms-page.component';
import { ShopPageComponent } from './pages/shop/shop-page.component';
import { ContentHeaderPageComponent } from './pages/cms/contentHeader/content-header-page.component';
import { FontsPageComponent } from './pages/cms/fonts/fonts-page.component';

export const appRoutes: Route[] = [
  {
    path: 'cms',
    component: CmsPageComponent,
    children: [
      { path: 'header', component: HeaderPageComponent },
      { path: 'content-header', component: ContentHeaderPageComponent },
      { path: 'footer', component: FooterPageComponent },
      { path: 'corporate', component: CorporatePageComponent },
      { path: 'fonts', component: FontsPageComponent },
    ],
  },
  {
    path: '',
    component: ShopPageComponent,
    children: [{ path: '', component: LandingPageComponent }],
  },
];
