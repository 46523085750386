import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-number-input',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './number-input.component.html',
  styles: ``,
})
export class NumberInputComponent {
  number = input<number>(0);
  label = input.required<string>();
  numberChange = output<number>();

  updateNumber($event: Event) {
    const target = $event.target as HTMLInputElement;
    this.numberChange.emit(+target.value);
  }
}
