<div class="flex flex-col items-start gap-2">
  @for (font of fonts(); track font.name) {
    <div
      class="text-gray-600 outline-none focus:outline-none dark:text-gray-300">
      <div class="flex rounded-lg bg-white p-4">
        <div
          class="p- peer h-10 min-w-72 content-center rounded-l-lg bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:border-rose-600 focus:outline-none focus:ring-sky-600">
          {{ font.name }}
        </div>
        <div class="relative bg-inherit">
          <div
            class="peer h-10 content-center rounded-r-lg bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:border-rose-600 focus:outline-none focus:ring-sky-600">
            <button type="submit" (click)="deleteFont(font)">Delete</button>
          </div>
        </div>
      </div>
    </div>
  } @empty {
    <li>There are no items.</li>
  }
  <div class="w-full border-b-2"></div>
  <label
    class="flex cursor-pointer appearance-none justify-center rounded-md border border-dashed border-gray-300 bg-white px-3 py-6 text-sm transition hover:border-gray-400 focus:border-solid focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
    tabindex="0">
    <span class="flex items-center space-x-2">
      <svg class="h-6 w-6 stroke-gray-400" viewBox="0 0 256 256">
        <path
          d="M96,208H72A56,56,0,0,1,72,96a57.5,57.5,0,0,1,13.9,1.7"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"></path>
        <path
          d="M80,128a80,80,0,1,1,144,48"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"></path>
        <polyline
          points="118.1 161.9 152 128 185.9 161.9"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"></polyline>
        <line
          x1="152"
          y1="208"
          x2="152"
          y2="128"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"></line>
      </svg>
      <span class="text-gray-600">
        @if (selectedFile()) {
          <span class="text-blue-600 underline">{{
            selectedFile()?.name
          }}</span>
        } @else {
          <span class="text-blue-600 underline">Select</span>
        }
      </span>
    </span>
    <input
      id="font-upload"
      type="file"
      class="sr-only"
      [formControl]="filename"
      (change)="selectFile($event)" />
  </label>

  <button
    (click)="save()"
    type="submit"
    class="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
    Upload
  </button>
</div>
