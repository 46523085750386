import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { ClientService } from '../client/client.service';
import { IGetGateway, ISaveGateway } from '../gateways';
import { IContentHeader } from './content-header.service';

export class ContentHeaderGateway
  implements
    IGetGateway<null, IContentHeader>,
    ISaveGateway<IContentHeader, IContentHeader>
{
  private http = inject(HttpClient);
  private configService = inject(ConfigService);
  private clientService = inject(ClientService);

  private apiUrl = this.configService.getApiUrl;
  private client = this.clientService.getClient;

  get(): Observable<IContentHeader> {
    return this.http.get<IContentHeader>(
      this.apiUrl + '/content-header/' + this.client
    );
  }

  save(query: IContentHeader): Observable<IContentHeader> {
    return this.http.post<IContentHeader>(
      this.apiUrl + '/content-header/',
      query
    );
  }

  upload(query: FormData): Observable<IContentHeader> {
    return this.http.post<IContentHeader>(
      this.apiUrl + '/content-header/banner',
      query
    );
  }
}
