import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { matFontDownload } from '@ng-icons/material-icons/baseline';

@Component({
  selector: 'app-cms',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterOutlet,
    RouterLink,
    NgIconComponent,
  ],
  viewProviders: [provideIcons({ matFontDownload })],
  templateUrl: './cms-page.component.html',
  styles: ``,
})
export class CmsPageComponent {}
