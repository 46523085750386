import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { IDeleteGateway, IGetGateway, IUploadGateway } from '../gateways';
import { IFont } from '../../entities/font-entity';

export class FontGateway
  implements
    IGetGateway<null, IFont[]>,
    IUploadGateway<FormData, IFont[]>,
    IDeleteGateway<IFont, IFont[]>
{
  private http = inject(HttpClient);
  private configService = inject(ConfigService);

  private apiUrl = this.configService.getApiUrl;

  get(): Observable<IFont[]> {
    return this.http.get<IFont[]>(this.apiUrl + '/fonts/');
  }

  upload(query: FormData): Observable<IFont[]> {
    return this.http.post<IFont[]>(this.apiUrl + '/fonts/', query);
  }

  delete(query: IFont): Observable<IFont[]> {
    return this.http.delete<IFont[]>(this.apiUrl + '/fonts/' + query.name);
  }
}
