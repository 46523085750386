import { Component, computed, inject, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from '../../services/config/config.service';
import { ClientService } from '../../services/client/client.service';
import {
  ContentHeaderService,
  IContentHeader,
} from '../../services/contentHeader/content-header.service';
import { LanguagePipe } from '../../pipes/language/language.pipe';

@Component({
  selector: 'app-content-header',
  standalone: true,
  imports: [CommonModule, LanguagePipe],
  templateUrl: './contentHeader.component.html',
  styles: ``,
})
export class ContentHeaderComponent {
  private contentHeaderService: ContentHeaderService =
    inject(ContentHeaderService);
  private configService = inject(ConfigService);
  private clientService = inject(ClientService);

  private apiUrl = this.configService.getApiUrl;
  private client = this.clientService.getClient;

  contentHeader: Signal<IContentHeader> = this.contentHeaderService.get();

  bannerPath = computed(() => {
    return this.apiUrl + '/content-header/' + this.client + '/banner';
  });
}
