<div class="text-gray-600 outline-none focus:outline-none dark:text-gray-300">
  <div class="flex rounded-lg bg-white p-4">
    <label
      class="p- peer flex h-10 cursor-pointer rounded-l-lg bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:border-rose-600 focus:outline-none focus:ring-sky-600"
      tabindex="0">
      <span class="flex items-center space-x-2">
        <svg class="h-6 w-6 stroke-gray-400" viewBox="0 0 256 256">
          <path
            d="M96,208H72A56,56,0,0,1,72,96a57.5,57.5,0,0,1,13.9,1.7"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"></path>
          <path
            d="M80,128a80,80,0,1,1,144,48"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"></path>
          <polyline
            points="118.1 161.9 152 128 185.9 161.9"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"></polyline>
          <line
            x1="152"
            y1="208"
            x2="152"
            y2="128"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"></line>
        </svg>
        <span class="text-gray-600">
          @if (selectedImage()) {
            <span class="text-blue-600 underline">{{
              selectedImage()?.name
            }}</span>
          } @else {
            <span class="text-blue-600 underline">Upload</span>
          }
        </span>
      </span>
      <input
        id="banner-upload"
        type="file"
        class="sr-only"
        [formControl]="filename"
        (change)="selectImage($event)" />
    </label>
    <div class="relative bg-inherit">
      <input
        [value]="text"
        (change)="updateText($event)"
        class="peer h-10 w-72 rounded-r-lg bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:border-rose-600 focus:outline-none focus:ring-sky-600"
        data-testid="image-alt"
        id="image-alt"
        placeholder="."
        type="text" />
      <label
        class="absolute -top-3 left-0 mx-1 cursor-text bg-inherit px-1 text-sm text-gray-500 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:text-sm peer-focus:text-sky-600"
        for="image-alt">
        {{ label() }}
      </label>
    </div>
  </div>
</div>
