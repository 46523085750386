import {
  Component,
  inject,
  OnDestroy,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HeaderService,
  IHeader,
} from '../../../services/header/header.service';
import { Subscription } from 'rxjs';
import { LanguagePipe } from '../../../pipes/language/language.pipe';
import { TextLanguageInputComponent } from '../../../components/inputs/textLanguageInput/text-language-input.component';
import { ImageInputComponent } from '../../../components/inputs/imageInput/image-input.component';

@Component({
  selector: 'app-header-page',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    LanguagePipe,
    TextLanguageInputComponent,
    ImageInputComponent,
  ],
  templateUrl: './header-page.component.html',
  styles: ``,
})
export class HeaderPageComponent implements OnDestroy {
  headerService = inject(HeaderService);

  header: WritableSignal<IHeader> = this.headerService.get();
  selectedLogo: WritableSignal<File | undefined> = signal(undefined);
  selectedRight: WritableSignal<File | undefined> = signal(undefined);
  selectedLeft: WritableSignal<File | undefined> = signal(undefined);

  private subscription: Subscription = new Subscription();
  langIso = 'deu';

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  save(): void {
    const newHeader: IHeader = { ...this.header() };

    if (this.selectedLogo() || this.selectedLeft() || this.selectedRight()) {
      const formData = new FormData();

      const logo = this.selectedLogo();
      const left = this.selectedLeft();
      const right = this.selectedRight();

      logo ? formData.append('logo', logo, logo.name) : null;
      left ? formData.append('left', left, left.name) : null;
      right ? formData.append('right', right, right.name) : null;
      formData.append('header', JSON.stringify(newHeader));

      this.selectedLogo.set(undefined);
      this.selectedLeft.set(undefined);
      this.selectedRight.set(undefined);

      this.subscription.add(
        this.headerService.upload(formData).subscribe(h => this.header.set(h))
      );
    } else {
      this.subscription.add(
        this.headerService.save(newHeader).subscribe(cd => this.header.set(cd))
      );
    }
  }

  selectLogo($event: File) {
    console.log($event);
    this.selectedLogo.set($event);
  }

  selectLeft($event: File) {
    this.selectedLeft.set($event);
  }

  selectRight($event: File) {
    this.selectedRight.set($event);
  }

  onTextChange(value: string) {
    const newHeader = this.header();
    if (
      newHeader.headlines.filter(t => t.isoCode === this.langIso).length > 0
    ) {
      newHeader.headlines = newHeader.headlines.map(t => {
        if (t.isoCode === this.langIso) {
          t.value = value;
          return t;
        }
        return t;
      });
    } else {
      newHeader.headlines.push({ value: value, isoCode: this.langIso });
    }
    this.header.set(newHeader);
  }

  onLogoAltChange(value: string) {
    const newHeader = this.header();
    newHeader.logo.alt = value;
    this.header.set(newHeader);
  }

  onLeftAltChange(value: string) {
    const newHeader = this.header();
    newHeader.leftImage.alt = value;
    this.header.set(newHeader);
  }

  onRightAltChange(value: string) {
    const newHeader = this.header();
    newHeader.rightImage.alt = value;
    this.header.set(newHeader);
  }

  onLangChange(value: string) {
    this.langIso = value;
  }
}
