import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

export interface Config {
  version: string;
  client: {
    name: string;
  };
  general: {
    apiUrl: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private config: Config | undefined;

  constructor(private http: HttpClient) {}

  get getApiUrl() {
    return this.config?.general.apiUrl;
  }

  async loadConfig(): Promise<void> {
    const config$ = this.http.get('/config/config.json');
    const config = await lastValueFrom(config$);
    console.log(config);
    this.config = config as Config;
  }
}
